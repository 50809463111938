<template>
  <div class="defalut-data-contaner">
      <!-- 设备信息s -->
      <div class="msg-box mt24 b">
        <div class="left">
          <div id="ec100" style="width: 600px; height: 300px"></div>
        </div>
        <div class="right">
          <div id="ec101" style="width: 520px; height: 300px"></div>
        </div>
      </div>
      <!-- 设备信息e -->
      <!-- 添加设备资料 s -->
      <!-- 添加设备资料 e -->
      <!-- 表格展示 start -->
      <el-row class="table-box mt20">
        <el-table
          :data="tableData"
          border
        >
          <el-table-column
            prop="orderNum"
            align="center"
            label="订单编号"
            min-width="120"
          >
            <template slot-scope="{ row }">{{ row.orderNum || "-" }}</template>
          </el-table-column>
          <el-table-column
            class-name="blue"
            align="center"
            prop="createTime"
            label="订单日期"
            min-width="100"
            > <template slot-scope="{ row }">{{
              row.createTime || "-"
            }}</template>
          </el-table-column>
          <el-table-column
            class-name="blue"
            align="center"
            prop="deviceNumber"
            label="设备编号"
            min-width="129"
            > <template slot-scope="{ row }">{{
              row.deviceNumber || "-"
            }}</template>
          </el-table-column>
          <el-table-column
            class-name="blue"
            align="center"
            prop="repairContent"
            label="报单内容"
            min-width="120"
            > <template slot-scope="{ row }">{{
              row.repairContent || "-"
            }}</template>
          </el-table-column>
          <el-table-column
            class-name="blue"
            align="center"
            prop="serviceTime"
            label="维修时长"
            min-width="100"
            > <template slot-scope="{ row }">{{
              row.serviceTime || "-"
            }}</template>
          </el-table-column>
          <el-table-column
            class-name="blue"
            align="center"
            prop="stopTime"
            label="故障停机时长"
            min-width="100"
            > <template slot-scope="{ row }">{{
              row.stopTime || "-"
            }}</template>
          </el-table-column>
          <el-table-column
            class-name="blue"
            align="center"
            prop="responseTime"
            label="响应时长"
            min-width="100"
            > <template slot-scope="{ row }">{{
              row.responseTime || "-"
            }}</template>
          </el-table-column>
        </el-table>
      </el-row>
      <!-- 表格展示 end -->
      <!-- 分页 -->
      <el-pagination
        background
        layout="prev, pager, next"
        :total="listTotal"
        :page-size="pageSize"
        :current-page="page"
        @current-change="pageChange"
        @prev-click="prevChange"
        @next-click="nextChange"
      >
      </el-pagination>
    <!-- <Footer></Footer> -->
  </div>
</template>

<script>
import * as echarts from "echarts";
import Layout from "@/components/Layout.vue";
import Header from "@/components/home/Header.vue";
import Nav from "@/components/home/Nav.vue";
import Footer from "@/components/home/Footer.vue";
export default {
  name: "YoufujichuangRepairrecord",
  components: {
    Layout,
    Header,
    Nav,
    Footer,
  },
  data() {
    return {
      dataShow1: [],
      tableData: [], //表格数据
      // 分页相关数据
      page: 1,
      pageSize: 5,
      listTotal: 0,
      id: "", //设备id

      deviceDetail: [], //设备详情
      dataShow1: [],
      dataShow2: [],
      deviceDetail1: {},
      dataShow3: [],
    };
  },

  mounted() {
    this.creatEc1();
    this.creatEc2();
  },
  created() {
    this.id = this.$route.query.id;

    // 设备文件-分页列表查询
    this.getDeviceList();

    // 通过id查询设备信息
    this.getDeviceRes();
    this.getDeviceRes1();
  },

  methods: {
    creatEc2() {
      var chartDom = document.getElementById("ec101");
      var myChart = echarts.init(chartDom);
      var option;

      option = {
        color: ["#65B581", "#FFCE34", "#FD665F"],

        title: {
          text: "维修频率(天)",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "value",
          boundaryGap: [0, 0.01],
        },
        yAxis: {
          type: "category",
          data: this.dataShow3,
        },
        series: [
          {
            name: "2011",
            type: "bar",
            data: this.dataShow2.map((item) => ({
              value: item.value,
              itemStyle: {
                color: item.color, // 为每个柱子设置颜色
              },
            })),
          },
        ],
      };

      option && myChart.setOption(option);
    },
    creatEc1() {
      var chartDom = document.getElementById("ec100");
      var myChart = echarts.init(chartDom);
      var option;

      option = {
        title: {
          //   text: "Referer of a Website",
          subtext: "Fake Data",
          left: "center",
        },
        tooltip: {
          trigger: "item",
        },
        legend: {
          orient: "vertical",
          left: "left",
        },
        series: [
          {
            name: "Access From",
            type: "pie",
            radius: "50%",
            data: this.dataShow1,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      };

      option && myChart.setOption(option);
    },
    // 通过id查询设备信息
    getDeviceRes1() {
      let paramData = {
        deviceId: this.id,
      };
      this.$API
        .BreakdownFrequency(paramData)
        .then((res) => {
          if (res.code == 1) {
            this.deviceDetail1 = res.data.list;
            // this.dataShow1 = this.deviceDetail.map((item) => {
            //   item.value = item.num;
            //   item.name = item.breakdownName;
            //   return { ...item };
            // });
            let color = ["#65B581", "#FFCE34", "#FD665F", "#FFCE34", "#FD665F"];
            this.dataShow2 = [18203, 23489, 29034, 104970, 131744];
            this.dataShow2 = this.dataShow2.map((item, index) => {
              return {
                value: item,
                color: color[index],
              };
            });
            this.dataShow3 = ["系统", "电脑", "主轴", "防护单元", "其他"];
            this.creatEc2();
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 通过id查询设备信息
    getDeviceRes() {
      let paramData = {
        deviceId: this.id,
      };
      this.$API
        .BreakdownCount(paramData)
        .then((res) => {
          if (res.code == 1) {
            this.deviceDetail = res.data.list;
            // this.dataShow1 = this.deviceDetail.map((item) => {
            //   item.value = item.num;
            //   item.name = item.breakdownName;
            //   return { ...item };
            // });
            this.dataShow1 = [
              { value: 1048, name: "系统" },
              { value: 735, name: "电脑" },
              { value: 580, name: "主轴" },
              { value: 484, name: "换刀机构" },
              { value: 300, name: "其他" },
            ];
            this.creatEc1();
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 设备文件-分页列表查询
    getDeviceList() {
      let paramData = {
        deviceId: this.id,
      };
      this.$API
        .faultList(paramData)
        .then((res) => {
          if (res.code == 1) {
            // this.listTotal = Number(res.data.total);
            this.tableData = [];
            this.tableData = res.data;
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 编辑
    handleEdit(index, item) {
      console.log("下载");
      // console.log(row);
      console.log(item);
      let url = item.fileUrl;
      let houzhuiArr = url.split(".");
      let houzhui = houzhuiArr[houzhuiArr.length - 1];
      if (houzhui == "pdf") {
        console.log("下载pdf文件");
        this.fileLinkToStreamDownload(url);
      } else {
        console.log(url, "url");
        let urlArr = url.split(",");
        console.log(urlArr);
        var arrayLength = urlArr.length;
        var i = 0;
        window.open(urlArr[i], "_wnd" + i);
        if (i < arrayLength) {
          i += 1;
          window.open(urlArr[i], "_wnd" + i);
        }
        if (i < arrayLength) {
          i += 1;
          window.open(urlArr[i], "_wnd" + i);
        }
        // for (var i = 0; i < arrayLength; i++) {
        //   window.open(urlArr[i], "_wnd" + i);
        // }
        // urlArr.forEach((item) => {
        //   console.log(item);
        //   window.open(item, "_self");
        // });
        // window.open(url, "_self");
      }
      return;
    },
    // 下载pdf方法
    fileLinkToStreamDownload(url) {
      let fileName = this.getDay();
      let reg =
        /^([hH][tT]{2}[pP]:\/\/|[hH][tT]{2}[pP][sS]:\/\/)(([A-Za-z0-9-~]+).)+([A-Za-z0-9-~\/])+$/;
      if (!reg.test(url)) {
        throw new Error("传入参数不合法,不是标准的文件链接");
      } else {
        let xhr = new XMLHttpRequest();
        xhr.open("get", url, true);
        xhr.setRequestHeader("Content-Type", `application/pdf`);
        xhr.responseType = "blob";
        let that = this;
        xhr.onload = function () {
          if (this.status == 200) {
            //接受二进制文件流
            var blob = this.response;
            that.downloadExportFile(blob, fileName);
          }
        };
        xhr.send();
      }
    },
    downloadExportFile(blob, tagFileName) {
      let downloadElement = document.createElement("a");
      let href = blob;
      if (typeof blob == "string") {
        downloadElement.target = "_blank";
      } else {
        href = window.URL.createObjectURL(blob); //创建下载的链接
      }
      downloadElement.href = href;
      // downloadElement.download =
      //   tagFileName +
      //   //下载后文件名
      //   document.body.appendChild(downloadElement);
      downloadElement.download = tagFileName;
      document.body.appendChild(downloadElement);
      downloadElement.click(); //点击下载
      document.body.removeChild(downloadElement); //下载完成移除元素
      if (typeof blob != "string") {
        window.URL.revokeObjectURL(href); //释放掉blob对象
      }
    },
    getDay() {
      let time = new Date(),
        year = time.getFullYear(),
        month = time.getMonth() + 1,
        day = time.getDate(),
        timeStem = time.getTime();
      return `${year}/${month}/${day}/${timeStem}.pdf`;
    },
    // 下载pdf文件方法结束
    // 删除
    handleDel(index, item) {
      // console.log(index, item);
      let paramData = {
        fileId: item.id,
      };
      this.$alert("确定要删除吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        showCancelButton: true,
        callback: (action) => {
          // 确定  confirm  取消 cancel
          if (action == "confirm") {
            this.$API
              .delDeviceFile(paramData)
              .then((res) => {
                if (res.code == 1) {
                  this.$message({
                    message: "删除成功",
                    type: "success",
                    duration: 500,
                  });
                  // 设备文件-分页列表查询
                  this.getDeviceList();
                } else {
                  this.$message.info(res.msg);
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
        },
      });
    },
    // 当前页码改变
    pageChange(page) {
      this.page = page;
      this.tableData = [];
      this.getDeviceList();
    },

    // 点击上一页
    prevChange() {},
    // 点击下一页
    nextChange() {},
    // 返回
    toDevice() {
      this.$router.back();
    },
  },
};
</script>

<style lang="scss">
.defalut-data-contaner {
  // 分頁
  .el-pagination {
    text-align: right;
    margin-bottom: 46px;
  }
  .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #333333;
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: red;
    color: #ffffff;
  }

  .table-img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
  .edit {
    color: #1577fc;
  }
  .del {
    color: #ff4656;
  }
  .line {
    width: 0px;
    height: 13px;
    border: 1px solid #c6ccda;
    margin: 10px;
  }
  width: 100%;
  .border {
    border-bottom: 1px solid #eeeeee;
  }
  .right {
    button {
      width: 96px;
      height: 40px;
      background: #efefef;
      border-radius: 4px 4px 4px 4px;
      img {
        width: 16px;
        height: 16px;
        margin-right: 5px;
        vertical-align: top;
      }
    }
  }
  //   设备信息
  .msg-box {
    // width: 1120px;
    .line1 {
      padding-bottom: 17px;
      border-bottom: 1px solid #f4c8cb;
      .id {
        color: #ff4656;
        margin-right: 12px;
      }
      .num {
        color: #99989c;
      }
    }
    .line2 {
      margin-top: 16px;
      span {
        margin-right: 43px;
      }
    }
  }
  //   添加设备资料
  .add {
    button {
      width: 149px;
      height: 40px;
      background: #1577fc;
      border-radius: 4px 4px 4px 4px;
      img {
        width: 16px;
        height: 16px;
        margin-right: 5px;
        vertical-align: top;
      }
    }
  }
  // 表格
  .table-box {
    margin-bottom: 20px;
    .handle {
      height: 39px;
      ul {
        height: 39px;
        line-height: 39px;
        display: flex;
        align-items: center;
      }
      li {
        float: left;
        font-size: 14px;
        font-weight: 400;
        // color: #2a89ff;
        line-height: 22px;
      }
      .line {
        width: 0px;
        height: 13px;
        border: 1px solid #c6ccda;
        margin: 12px 12px;
      }
    }
  }
}
.msg-box {
  display: flex;
  justify-content: space-around;
  .left,
  .right {
    width: 550px;
    height: 332px;
    background: #ffffff;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #dedede;
    opacity: 0.72;
  }
  .left,
  .right {
    padding-top: 20px;
    padding-left: 20px;
  }
}
</style>
